/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import Layout from '../_ens/layout';
import BasePage from './BasePage';
import { Logout, AuthPage } from './modules/Auth';
import ErrorPage from './modules/Errors/ErrorPage';
import { getParentRoute } from './servics/CommonFunctions';
import UpdateTimeAppointment from '../app/modules/updateTimeAppointment/index';

export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null
    }),
    shallowEqual
  );

  let redirectUrl = getParentRoute();

  return (
    <Switch>
      <Route path={'/update-appointment-time/:token/:email'} component={UpdateTimeAppointment} />
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to={redirectUrl} />
      )}

      <Route path={redirectUrl + 'error'} component={ErrorPage} />
      <Route path={redirectUrl + 'logout'} component={Logout} />
      <Route
        path="/privacy-policy"
        component={() => {
          window.location.href =
            'https://www.ens.it/images/immagini/2019/05/Informativa_sulla_privacy.pdf';
          return null;
        }}
      />
      <Route
        path="/cokies"
        component={() => {
          window.location.href = 'https://www.ens.it/cookie-policy';
          return null;
        }}
      />
      <Route path={'/documentation'} component={ErrorPage} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
